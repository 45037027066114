import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

export const Navigation = ({currentPage, onPageChange, imageNum}) => {
  const [loading, setLoading] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentMint, setMint] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const burgerOrX = () => {
    if (menuOpen) {
      return (<div className="xClose">✕</div>);
    } else {
      return (
        <>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </>
      )
    }
  }

  const changePage = (e) => {
    onPageChange(`/${e.target.id}`);
    toggleMenu();
  }

  // const menuSize = () => {
  //
  // }

  // width: `${menuOpen ? 400 : 0}px`

  const desktopSidepanelStyle = {
    transform: `translate(${menuOpen ? '80px' : '-400px'})`,
    borderRight: `${menuOpen ? 0.5 : 0}px solid`
  }

  const mobileSidepanelStyle = {
    transform: `translate(0, ${menuOpen ? '80px' : '-450px'})`,
  }

  return (<>
    <div className="navigation">
      <div className="burgerContainer" onClick={toggleMenu}>
        {burgerOrX()}
       </div>
      <h1 className="title">Memories of Qilin</h1>
    </div>
    <div
      className="sidepanel"
      style={(width <= 768) ? mobileSidepanelStyle : desktopSidepanelStyle}
    >
      <Link to={`/gallery?mint=${imageNum}`} onClick={(e) => changePage(e)} id="gallery">Gallery View</Link>
      <Link to="/about" onClick={(e) => changePage(e)} id="about">Project Description</Link>
      <Link to="/artist" onClick={(e) => changePage(e)} id="artist">About the Artist</Link>
      <Link to="/prints" onClick={(e) => changePage(e)} id="prints">High Resolution Prints</Link>
      <a href="https://archipelago.art/collections/memories-of-qilin" target="_blank">Marketplace</a>
      <Link to="/acknowledgements" onClick={(e) => changePage(e)} id="acknowledgements">Acknowledgements</Link>

    </div>
    </>
  )
}
