import React, { useState, useEffect } from 'react';
import { SocialIcon } from 'react-social-icons';

export const PrintsView = () => {

  return (
    <div className="browseView">
      <div className="aboutWrapper">
        <div className="leftPanel"></div>
        <div className="middlePanelAbout">
        <div className="smallSpace"></div>

          <div className="aboutHeader">High Resolution Prints</div>

          <p className="aboutParagraph">
          Official museum-quality prints are now offered through <a href="https://www.artxcode.io/artist-prints/emily-xie" target="_blank">ARTXCODE</a>. Each piece will be hand-signed and editioned by the artist. They will be printed on 20 x 28 Hahnemühle German Etching paper.
          </p>

          <p className="aboutParagraph">
           As an option, prints may be framed, with various selections available. All frames are handmade in NYC, and will use museum-grade anti-reflective & UV-protected plexiglass. For those who don't want to choose the wood color themselves, the artist recommends white for all palettes except for Vermilion Bird or black backgrounds, in which case, a black frame is better suited.
          </p>


          <p className="aboutParagraph">
          For all inquiries or further information related to prints, please contact <a href="https://www.artxcode.io/artist-prints/emily-xie" target="_blank">ARTXCODE</a>.


          </p>

        </div>
        <div className="rightPanel"></div>
      </div>
    </div>
  );
}
