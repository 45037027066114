import React, { useState, useEffect } from 'react';
import { SocialIcon } from 'react-social-icons';

export const ArtistView = () => {

  return (

    <div className="browseView">
      <div className="aboutWrapper">
        <div className="leftPanel">
        </div>
        <div className="middlePanelAbout">
        <div className="smallSpace"></div>
          <div className="aboutHeader">About the Artist</div>
          <p className="aboutParagraph">
          <a className="aboutLink" target="_blank" href="https://xie-emily.com">Emily Xie</a> is an Asian-American generative artist & engineer living in NYC. She works primarily with algorithms and data to explore the interplay between the organic and the systematic, as well the space between the abstract and the representational.

          <p className="aboutParagraph">
          She is an open-source contributor to <a href="https://github.com/processing/p5.js" target="_blank">p5.js</a>, having programmed the library's front-page animation and teaching students across the country how to use the framework. Her creative coding work is collected internationally, and has exhibited at NFT Art Week Shenzhen, New York Fashion Week, VICE Magazine, Gizmodo, and on the front pages of Hacker News and the official Tensorflow site.</p>

          <p className="aboutParagraph"></p>
          Emily received her formal training at Harvard University, where she earned a Bachelor's in History of Art and Architecture, and a Master's in Computational Science and Engineering.
          In 2016, she was an Artist-in-Residence at <a href="https://pioneerworks.org/" target="_blank">Pioneer Works</a>, where she programmed interactive generative art installations.
          </p>

          <SocialIcon className="my-social-icon" target="_blank" url="https://twitter.com/emilyxxie" bgColor="#000000" style={{ height: 40, width: 40 }}/>
          <p className="aboutParagraph">
            <a href="https://xie-emily.com" target="_blank">Learn more about Emily's work →</a>
          </p>
        </div>
        <div className="rightPanel"></div>
      </div>
    </div>
  );
}
