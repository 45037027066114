import React, { useState, useEffect } from 'react';
import { ImagePanel } from '../components/ImagePanel'
import ReactTooltip from 'react-tooltip';
import loading from '../ajax-loader.gif'; // with import
import InfiniteScroll from 'react-infinite-scroll-component';

// import { Oval } from  'react-loader-spinner'


// import { useHistory } from "react-router-dom";

export const BrowseView = ({totalImages, imageNum, setImageNum, loadingImages}) => {
  const [toolTipHidden, setTooltipHidden] = useState(false);
  const [imageStrings, setImageStrings] = useState([String(0).padStart(4, '0')]);
  const [width, setWidth] = useState(window.innerWidth);
  // const history = useHistory();

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('keydown', onKeyDown);
    return () => {
      document.body.removeEventListener('keydown', onKeyDown);
    }
  }, [imageNum]);

  function onKeyDown(event) {
    const isUp = event.key === 'ArrowUp';
    const isLeft = event.key === 'ArrowLeft';
    const isRight = event.key === 'ArrowRight';
    const isDown = event.key === 'ArrowDown';

    if ( isUp || isLeft) {
      seePrev();
    }

    if ( isDown || isRight ) {
      seeNext();
    }
  }

  const seePrev = () => {
    const prevNum = imageNum > 0 ? imageNum - 1 : 0;
    setImageNum(prevNum);
    window.history.pushState(null, "Memories of Qilin", `?mint=${prevNum}`)
  }

  const seeNext = () => {
    const nextNum = imageNum < totalImages - 1 ? imageNum + 1 : imageNum;
    setImageNum(nextNum);
    window.history.pushState(null, "Memories of Qilin", `?mint=${nextNum}`)
  }

  const imageStr = String(imageNum).padStart(4, '0');

  const getArrowStyle = (direction) => {
    if (direction == "right") {
      return {
        cursor: imageNum >= totalImages - 1 ? "default" : "pointer",
        color: imageNum >= totalImages - 1 ? "white" : "black"
      }
    } else if (direction == "left") {
        return {
          cursor: imageNum <= 0 ? "default" : "pointer",
          color: imageNum <= 0 ? "white" : "black"
        }
      }
  }


  // <div className="overlay"><div className="overlay-content"><p>Loading images...</p><Oval color="#FFFFFF" height={80} width={80} /></div></div>

  const fetchMoreData = () => {
    if (imageStrings.length > totalImages) {
      return;
    }
    setImageStrings([
      ...imageStrings,
      String(imageStrings.length).padStart(4, '0')
    ])
  };

  if (width <= 768) return (
    <div className="browseViewMobile">
      <div className="middlePanelMobile">
        <InfiniteScroll
          dataLength={imageStrings.length}
          next={fetchMoreData}
          hasMore={true}
          loader={<h4 className="ImageInfoMobile">That's all!</h4>}
        >
          {
            imageStrings.map(imageString => {
              return (
                <div>
                  <img
                  className="browseImageMobile"
                  src={`https://artblocks-mainnet.s3.amazonaws.com/28200${imageString}.png`}
                  />
                  <p className="ImageInfoMobile">
                    Memories of Qilin #{imageString}
                    <br />
                    <a href={`https://artblocks.io/token/28200${imageStr}`} className="sub" target="_blank">
                      view on Art Blocks →
                    </a>
                  </p>
                </div>
              )
            })
          }
        </InfiniteScroll>
      </div>
    </div>
  )

  return (
    <div className="browseView">
      <div className="leftPanel">
      </div>
      <div className="arrowLeft" onClick={seePrev} style={getArrowStyle("left")} data-tip="Note: you may also use <br>your keyboard arrows <br> to scroll.">◀</div>
      <ImagePanel imageStr={imageStr}/>
      <div className="arrowRight" onClick={seeNext} style={getArrowStyle("right")} data-tip="Note: you may also use <br>your keyboard arrows <br> to scroll.">▶</div>
      <div className="rightPanel">
        <p className="header">Memories of Qilin #{imageNum}</p>
        <p className="dash"><b>—</b></p>
        <p className="sub"><a href={`https://artblocks.io/token/28200${imageStr}`} className="sub" target="_blank">view on Art Blocks →</a></p>
        {loadingImages &&
        <div className="loading">
        <img className="loadingIcon" src={loading} width="40" height="40"/><p className="sub">Loading images...</p></div>
        }
      </div>
      {!toolTipHidden &&
      <ReactTooltip delayShow={300} multiline={true} afterShow={() => { setTimeout(() => {ReactTooltip.hide(); setTooltipHidden(true)}, 5000)}}/>}
    </div>
  );
}
