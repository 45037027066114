import React, { useState, useEffect } from 'react';
import { SocialIcon } from 'react-social-icons';

export const AcknowledgementsView = () => {

  return (
    <div className="browseView">
      <div className="aboutWrapper">
        <div className="leftPanel"></div>
        <div className="middlePanelAbout">
        <div className="smallSpace"></div>

          <div className="aboutHeader">Acknowledgements</div>

          <p className="aboutParagraph">
          This series is the result of many months of hard work, and I’m lucky to have had the support of amazing people throughout this journey.</p>

          <p className="aboutParagraph">
          Thanks to the following friends, loved ones, and fellow artists who provided valuable feedback, support, and encouragement at various points throughout the process: Nitin Kumar, Ngozi Nwogwugwu, Sunita Kochhar, Emily Edelman, Andrew Badr, Sofia Garcia, Aaron Penne, ippsketch, Raeez Lorgat, Dmitri Cherniak, Nicholas Platt, Matthew Brown, Piter Pasma, Neel Shivdasani and the artists of #genartclub. Thanks to the Art Blocks team (Sarah Rossien, Jeff Davis, shlby) for answering my countless questions.
          </p>

          <p className="aboutParagraph">
          A special shoutout goes to Thomas Lin Pedersen who was kind enough to give me his code snippet that finds the intersection of two line segments in the face of last-minute complications, and to Ngozi who helped me mobile-optimize this site.
          </p>

          <p className="aboutParagraph">
          And finally, an extra special shoutout goes to my friend and talented fellow artist David Lu (aka Conundrumer) for bugging me until I submitted my Art Blocks application, and for his invaluable help in setting up the Verlet physics engine that this piece adapts.
          </p>

          <p className="aboutParagraph">
          Thanks everyone––I could not have done this without you, and you have all made this art project so much more personal and meaningful to me.
          </p>
        </div>
        <div className="rightPanel"></div>
      </div>
    </div>
  );
}
