import { BrowseView } from './pages/BrowseView';
import { AboutView } from './pages/AboutView';
import { ArtistView } from './pages/ArtistView';
import { AcknowledgementsView } from './pages/AcknowledgementsView';
import { PrintsView } from './pages/PrintsView';
import { Navigation } from './components/Navigation';
import React, { useState, useEffect } from 'react';
import { useLocation, NavLink } from "react-router-dom";

const IMAGE_URL = (i) => {
  return `https://artblocks-mainnet.s3.amazonaws.com/28200${i}.png`;
}



function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App() {
  let totalImages = 218;
  let location = useLocation();
  let query = useQuery();
  let mintNum = query.get("mint");
  const isValidMint = (mintNum) => {
    if (!isNaN(Number(mintNum)) && mintNum >= 0 && mintNum <= totalImages) {
      return true;
    }
    return false;
  }
  const [pageView, setPageView] = useState(location.pathname);

  const [imageNum, setImageNum] = useState(isValidMint(mintNum) ? Number(mintNum) : 0);

  let images = [];
  let image_list = [];

  const preload = (image_list) => {
    for (var i = 0; i < image_list.length; i++) {
      images[i] = new Image();
      images[i].src = image_list[i];
    }

    console.log("preload done");
  }

  useEffect(() => {
    for (let i = 0; i <= totalImages; i++) {
      const image_num = String(i).padStart(4, '0');
      image_list.push(IMAGE_URL(image_num));
    }
    preload(image_list);
  }, [])

  const getMainView = () => {
    if (pageView == "/gallery" || pageView == "/") {
      return (<BrowseView totalImages={totalImages + 1} imageNum={imageNum} setImageNum={setImageNum}/>)
    } else if (pageView == "/about") {
      return (<AboutView />);
    } else if (pageView == "/artist") {
      return (<ArtistView />);
    } else if (pageView == "/acknowledgements") {
      return (<AcknowledgementsView />);
    } else if (pageView == "/prints") {
      return (<PrintsView />);
    }
  }

  return (
    <div className="App">
      <Navigation currentPage={pageView} onPageChange={setPageView} imageNum={imageNum}/>
      { getMainView() }
    </div>
  );
}

export default App;
