import React, { useState, useEffect } from 'react';

export const AboutView = () => {

  return (

    <div className="browseView">
      <div className="aboutWrapper">
        <div className="leftPanel">
        </div>
        <div className="middlePanelAbout">
        <div className="smallSpace"></div>
          <div className="aboutHeader">Memories of Qilin</div>

          <p className="aboutParagraph">
          <i>Memories of Qilin</i> is a code-based generative art project inspired by traditional East Asian art. It channels the sense of movement and fluidity found in the region's
          classical paintings, while drawing from the colors, patterns, and forms of woodblock.</p>
          <p className="aboutParagraph">
          Specifically, the project explores the concept of folklore, evoking the mythological imagery of dragons, phoenixes, flowers, and mountains. The title references a fabled chimerical beast within East Asian mythology (while the qilin is its Chinese name, it is also known in Korea as the girin and Japan as the kirin) that represents prosperity and luck.
          </p>
          <p className="aboutParagraph">
          Viewers are invited to interpret elusive forms that verge on representation. As with the stories passed on through generations, each piece is imagined, organic, and ever-in-flux.
          </p>

          <p className="aboutParagraph">
          The project employs a wide variety of techniques including masking, geometric design, a simple physics engine, noise fields, and image processing, among other methods. It is programmed in p5.js.
          </p>

          <p className="miniDivider">––</p>


          <p className="aboutParagraph"><i>Memories of Qilin</i> was released on <a href="https://www.artblocks.io/project/282/">Art Blocks</a>.</p>

          <div className="space"></div>
        </div>
        <div className="rightPanel"></div>
      </div>
    </div>
  );
}
